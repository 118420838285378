/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 1441px) {


/* global
----------------------------------------------------------------------------------------------*/

.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.flex-list {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-flex-flow: row wrap;}


/* structure 
----------------------------------------------------------------------------------------------*/
.wrapper {width: 1170px; margin: 0 auto;}


/* header
----------------------------------------------------------------------------------------------*/
header {

}

/* middle
----------------------------------------------------------------------------------------------*/

.top_back {
	.breadcrumb {
		ul {
			li {
				a {font-size: 16px;}
			}
		}
	}
	.navSlider {
		.nav {
			h5 {font-size: 16px;}
		}
	}
}
.SlideProd {
	.wrap_slide {width: 1170px;}
	.logo_prod img {width: 300px;}
}

.wrap_campaign {
	.wrapper {width: 1170px;}
}

.page_screen_age {
    overflow: hidden; min-height: unset;
    @include boxSizing(initial);
    .drop_content {
        h6 {font-size: 20px; margin-bottom: 15px;}
        h2 {font-size: 40px; margin-bottom: 20px;}
    }
    .drop_content {
        form .row .col {margin: 0 24px;}
        .link {
            .btn {font-size: 16px; line-height: 46px; min-width: 250px;}
            > span {font-size: 14px;}
        }
        form .row{ margin-bottom: 20px; }
    }
    .content_pg_Age{
    	top: 50%;
        .logo_bir{ margin: 0 auto 20px; }
        .nav_lang{ margin-bottom: 20px; }
    }
}
/* footer
----------------------------------------------------------------------------------------------*/



} /* end media*/
